import React, { useState } from 'react';

import BaseAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';

export type AppBarUIInterface = {
  isAuthenticated: boolean
  username: string
  handleSignIn: any
  handleSignOut: any
};

export function AppBarUI({
  isAuthenticated, username, handleSignIn, handleSignOut,
}: AppBarUIInterface) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  let initials = username?.split(' ').map((n) => n[0]).join('') || '';
  initials = initials.length <= 2 ? initials : `${initials[0]}${initials[initials.length - 1]}`;

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BaseAppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Data Lake - Drop Zone
        </Typography>
        {!isAuthenticated && (
          <Button color="inherit" onClick={handleSignIn}>Sign In</Button>
        )}
        {isAuthenticated && (
          <>
            <IconButton onClick={handleMenu} sx={{ p: 0 }}>
              <Avatar
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
              >
                {initials}
              </Avatar>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </BaseAppBar>
  );
}
