type ContentTypeOption = {
  key: string;
  label: string;
  tooltip: string;
};

export const contentTypeOptions: ContentTypeOption[] = [
  {
    key: 'text/vnd.transport.kgco2e.v1+csv',
    label: 'Transport (kg co2e) .csv - v1',
    tooltip: 'tooltip',
  },
  {
    key: 'text/vnd.redpoints.incidents.v1+csv',
    label: 'Redpoints Incidents .csv - v1',
    tooltip: 'tooltip',
  },
  {
    key: 'text/vnd.distributor.forecast.v1+csv',
    label: 'Distributor Forecast .csv - v1',
    tooltip: 'tooltip',
  },
  {
    key: 'text/vnd.quickbooks.v1+csv',
    label: 'Quickbooks .csv - v1',
    tooltip: 'tooltip',
  },
];

const findContentTypeLabelFromKey = (key: string): string => {
  const ct = contentTypeOptions.find((contentType) => contentType.key === key);
  return ct?.label || key;
};

export type File = {
  id: string;
  filename: string;
  contentType: string;
  uploadedAt: string;
  author: string;
};

export type GetFilesResponse = {
  files: File[];
  status: number;
  error: string;
};

export const getFiles = (token: string): Promise<GetFilesResponse> =>
  new Promise((resolve) => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    const bearer = `Bearer ${token}`;
    headers.append('Authorization', bearer);

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const url = `${process.env.REACT_APP_API_V1_BASE_URL}/files`;

    fetch(url, requestOptions).then((dataResp) => {
      dataResp.json().then((msg) => {
        const response: GetFilesResponse = {
          files: msg.data,
          status: dataResp.status,
          error: msg.error,
        };

        if (dataResp.status !== 200) {
          response.files = [];
          resolve(response);
          return;
        }

        response.files = response.files.map<File>((file) => ({
          ...file,
          contentType: findContentTypeLabelFromKey(file.contentType),
        }));

        resolve(response);
      });
    });
  });

export type UploadReq = {
  filename: string;
  content: string;
  contentType: string;
};

export type UploadResp = {
  file: File;
  status: number;
  error: string;
};

export const uploadFile = (token: string, req: UploadReq): Promise<UploadResp> =>
  new Promise((resolve) => {
    const headers = new Headers();
    headers.append('Accept', 'application/json');

    const bearer = `Bearer ${token}`;
    headers.append('Authorization', bearer);

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(req),
    };

    const url = `${process.env.REACT_APP_API_V1_BASE_URL}/files`;

    fetch(url, requestOptions).then((dataResp) => {
      dataResp.json().then((msg) => {
        const response: UploadResp = {
          file: msg.data,
          status: dataResp.status,
          error: msg.error,
        };

        if (dataResp.status !== 201) {
          resolve(response);
        }

        resolve(response);
      });
    });
  });
