import React, { ChangeEvent, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../../authConfig';
import { DropZoneUI } from './DropZoneUI';
import { uploadFile, UploadReq, contentTypeOptions, UploadResp } from '../api';
import { useAppDispatch } from '../../hooks';
import { AppDispatch } from '../../store';
import { addMessage, Message } from '../../features/messages/messagesSlice';

type UploadFile = {
  contents: string;
  filename: string;
  contentType: string;
};

export default function DropZone() {
  const { instance, accounts } = useMsal();
  const dispatch: AppDispatch = useAppDispatch();
  const [contentType, setContentType] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);

  const [uploading, setUploading] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);

  const handleSendToCloud = () => {
    setUploading(true);
    setUploadError(false);
    setUploaded(false);

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(({ idToken }) => {
        const promises: Promise<UploadResp>[] = [];
        for (const file of uploadFiles) {
          const req: UploadReq = {
            filename: file.filename,
            content: file.contents,
            contentType,
          };

          const promise = uploadFile(idToken, req);
          promises.push(promise);
        }

        return Promise.all(promises);
      })
      .then((resArray) => {
        for (const res of resArray) {
          let msg: Message = {
            text: 'You successfully uploaded a file.',
            severity: 'success',
          };

          if (res.error) {
            setUploadError(true);
            msg = { text: res.error, severity: 'error' };
          }

          dispatch(addMessage(msg));
        }

        setUploading(false);
        setUploaded(true);
      });
  };

  const handleSetContentType = (type: string) => {
    setUploadError(false);
    setUploaded(false);

    setContentType(type);
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null) {
      const msg: Message = {
        text: 'File could not be read. Please try again.',
        severity: 'error',
      };
      dispatch(addMessage(msg));
      return;
    }

    const files = Array.from(e.target.files);

    setUploadError(false);
    setUploaded(false);

    const nextUploadFiles: UploadFile[] = [];
    const promises: Promise<void>[] = [];

    for (const file of files) {
      const promise = new Promise<void>((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = (event) => {
          const contents = event?.target?.result?.toString();

          if (contents) {
            nextUploadFiles.push({
              contents,
              filename: file.name,
              contentType,
            });
          }

          resolve();
        };

        fileReader.readAsBinaryString(file);
      });

      promises.push(promise);
    }

    Promise.all(promises).finally(() => {
      setUploadFiles(nextUploadFiles);
    });
  };

  return (
    <DropZoneUI
      uploadFile={handleUploadFile}
      sendToCloud={handleSendToCloud}
      setContentType={handleSetContentType}
      uploading={uploading}
      uploaded={uploaded}
      uploadError={uploadError}
      filename={uploadFiles.map((file) => file.filename).join(', ')}
      contentTypeOptions={contentTypeOptions}
      contentType={contentType}
    />
  );
}
