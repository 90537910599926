import React from 'react';

import { useAppSelector, useAppDispatch } from '../../hooks';
import { AppDispatch } from '../../store';
import { removeMessage, selectMessages } from '../../features/messages/messagesSlice';
import FeedbackUI from './FeedbackUI';

export default function Feedback() {
  const dispatch: AppDispatch = useAppDispatch();
  const messages = useAppSelector(selectMessages);

  const handleClose = (key: string) => {
    dispatch(removeMessage(key));
  };

  return (
    <FeedbackUI
      closeAlert={handleClose}
      messages={messages}
    />
  );
}
