import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

import type { RootState } from '../../store';

type MessagesState = {
  data: Record<string, Message | null>,
};

export type Message = {
  text: string,
  severity: AlertColor,
};

export type AlertColor = 'success' | 'info' | 'warning' | 'error';

const initialState: MessagesState = {
  data: {},
};

const addMessageAction = (state: MessagesState, { payload }: PayloadAction<Message>) => {
  state.data = {
    ...state.data,
    [uuidv4()]: payload,
  };
};

const removeMessageAction = (state: MessagesState, { payload }: PayloadAction<string>) => {
  state.data = {
    ...state.data,
    [payload]: null,
  };
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: addMessageAction,
    removeMessage: removeMessageAction,
  },
});

export const {
  addMessage,
  removeMessage,
} = messagesSlice.actions;

export const selectMessages = (state: RootState) => {
  const messages: Record<string, Message> = {};

  Object.keys(state.messages.data).forEach((key) => {
    const msg = state.messages.data[key];
    if (msg !== null) {
      messages[key] = msg;
    }
  });

  return messages;
};

export default messagesSlice.reducer;
