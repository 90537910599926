import React from 'react';

import './styles.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material';

type FeedbackUIInterface = {
  closeAlert: (key: string) => void;
  messages: Record<string, Message>;
};

type Message = {
  text: string;
  severity: AlertColor;
};

export default function FeedbackUI({ closeAlert, messages }: FeedbackUIInterface) {
  return (
    <div className="FeedbackUI-stack">
      {Object.keys(messages).map((key) => {
        return messages[key] === null ? null : (
          <Snackbar
            className="FeedbackUI-snackbar"
            key={key}
            open
            autoHideDuration={6000}
            onClose={(_: any, reason: string) => {
              if (reason === 'clickaway') {
                return;
              }

              closeAlert(key);
            }}
          >
            <Alert
              variant="filled"
              severity={messages[key]?.severity}
              sx={{ width: '100%' }}
              onClose={() => closeAlert(key)}
            >
              {messages[key]?.text}
            </Alert>
          </Snackbar>
        );
      })}
    </div>
  );
}
