import React, { MouseEventHandler } from 'react';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export type FileTableUIInterface = {
  loading: boolean
  loaded: boolean
  loadError: boolean

  files: File[];
  refreshFiles: MouseEventHandler
};

export type File = {
  id: string,
  filename: string,
  contentType: string,
  uploadedAt: string,
  author: string,
};

const columns: string[] = [
  'Filename',
  'Content Type',
  'Upload Date',
  'Author',
];

type Row = {
  id: string,
  filename: string,
  contentType: string,
  uploadedAt: Date,
  author: string,
};

const createRow = (f: File): Row => ({
  id: f.id,
  filename: f.filename,
  contentType: f.contentType,
  uploadedAt: new Date(f.uploadedAt),
  author: f.author,
});

const renderToolbar = (refreshFiles: MouseEventHandler) => (
  <Toolbar>
    <Typography
      sx={{ flex: '1 1 100%' }}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      Uploaded Files
    </Typography>
    <IconButton
      onClick={refreshFiles}
    >
      <RefreshIcon />
    </IconButton>
  </Toolbar>
);

const renderRow = (row: Row) => (
  <TableRow
    key={row.id}
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >
    <TableCell>{row.filename}</TableCell>
    <TableCell>{row.contentType}</TableCell>
    <TableCell>{row.uploadedAt.toLocaleDateString()}</TableCell>
    <TableCell>{row.author}</TableCell>
  </TableRow>
);

const renderTable = (files: File[]) => {
  const rows = files
    .map((file) => createRow(file))
    .sort((a, b) => b.uploadedAt.valueOf() - a.uploadedAt.valueOf()); // Reverse Order

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col}>{col}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function FileTableUI({
  files,
  refreshFiles,
}: FileTableUIInterface) {
  return (
    <Paper sx={{ height: '100%' }}>
      {renderToolbar(refreshFiles)}
      {renderTable(files)}
    </Paper>
  );
}
