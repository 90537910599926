import React, { useEffect } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import DropZone from './components/DropZone';
import FileTable from './components/FileTable';
import { AppBar } from './components/AppBar';
import { loginRequest } from './authConfig';
import Feedback from './components/Feedback';

function AuthenticatedContent(isAuthenticated: boolean, inProgress: InteractionStatus) {
  return isAuthenticated && inProgress === InteractionStatus.None ? (
    <>
      <Stack sx={{ width: '100%', height: '100%' }} direction="row">
        <div style={{ margin: '5px 0px 5px 5px' }}>
          <DropZone />
        </div>
        <div style={{ margin: 5, width: '100%' }}>
          <FileTable />
        </div>
      </Stack>
      <Feedback />
    </>
  ) : null;
}

function MainContent(isAuthenticated: boolean, inProgress: InteractionStatus) {
  return (
    <Stack sx={{ width: '100%', height: '100vh' }} direction="column">
      <AppBar isAuthenticated={isAuthenticated} />

      <AuthenticatedTemplate>
        {AuthenticatedContent(isAuthenticated, inProgress)}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Container sx={{ width: '100%', height: '100%', textAlign: 'center' }}>
          <h5 className="card-title">Please Sign In.</h5>
        </Container>
      </UnauthenticatedTemplate>
    </Stack>
  );
}

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    instance.ssoSilent({
      ...loginRequest,
      account: accounts[0],
    });
  }, [instance, accounts]);

  const theme = React.useMemo(
    () =>
      // eslint-disable-next-line implicit-arrow-linebreak
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {MainContent(isAuthenticated, inProgress)}
    </ThemeProvider>
  );
}

export default App;
