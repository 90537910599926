import React, { ChangeEventHandler, MouseEventHandler, useRef } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export type DropZoneUIInterface = {
  sendToCloud: MouseEventHandler;
  uploadFile: ChangeEventHandler;
  setContentType: (type: string) => void;

  uploading: boolean;
  uploaded: boolean;
  uploadError: boolean;

  contentTypeOptions: ContentTypeOption[];

  filename: string;
  contentType: string;
};

export type ContentTypeOption = {
  key: string;
  label: string;
  tooltip: string;
};

const Input = styled('input')({
  display: 'none',
});

const uploadButtonColor = (uploaded: boolean, uploadError: boolean) => {
  if (!uploaded) {
    return 'primary';
  }

  if (!uploadError) {
    return 'success';
  }

  return 'error';
};

export function DropZoneUI({
  sendToCloud,
  uploadFile,
  setContentType,
  uploading,
  uploaded,
  uploadError,
  contentTypeOptions,
  filename,
  contentType,
}: DropZoneUIInterface) {
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleContentTypeChange = (event: SelectChangeEvent) => {
    setContentType(event.target.value as string);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Stack spacing={2}>
          <TextField
            sx={{ width: '100%' }}
            disabled
            InputLabelProps={{ shrink: !!filename }}
            label="Filename"
            variant="outlined"
            value={filename}
            onClick={() => uploadRef.current?.click()}
          />
          <Input accept=".csv" type="file" ref={uploadRef} onChange={uploadFile} multiple />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Content Type</InputLabel>
            <Select
              sx={{ width: '100%' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contentType}
              label="Content Type"
              onChange={handleContentTypeChange}
            >
              {contentTypeOptions.map((opt) => (
                <MenuItem key={opt.key} value={opt.key}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          sx={{ flex: '1 1 50%', whiteSpace: 'nowrap' }}
          fullWidth
          disabled={uploading}
          variant={!filename ? 'contained' : 'outlined'}
          color={!filename ? 'primary' : 'secondary'}
          onClick={() => uploadRef.current?.click()}
        >
          Choose file
        </Button>
        <LoadingButton
          sx={{ flex: '1 1 50%', whiteSpace: 'nowrap' }}
          disabled={!filename || !contentType || uploading}
          variant="contained"
          color={uploadButtonColor(uploaded, uploadError)}
          loading={uploading}
          onClick={sendToCloud}
        >
          Send to Cloud
        </LoadingButton>
      </CardActions>
    </Card>
  );
}
