import React from 'react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../../authConfig';
import { AppBarUI } from './AppBarUI';

export type AppBarInterface = {
  isAuthenticated: boolean
};

export function AppBar({ isAuthenticated }: AppBarInterface) {
  const { instance, accounts } = useMsal();
  const username = accounts[0]?.name || '';

  const handleSignIn = () => {
    instance.loginRedirect(loginRequest)
      .catch((err) => {
        console.error({ err });
      });
  };

  const handleSignOut = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
  };

  return (
    <AppBarUI
      isAuthenticated={isAuthenticated}
      username={username}
      handleSignIn={handleSignIn}
      handleSignOut={handleSignOut}
    />
  );
}
