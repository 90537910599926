import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';

import { loginRequest } from '../../authConfig';
import { FileTableUI, File } from './FileTableUI';
import { getFiles } from '../api';
import { useAppDispatch } from '../../hooks';
import { AppDispatch } from '../../store';
import { addMessage, Message } from '../../features/messages/messagesSlice';

export default function FileTable() {
  const { instance, accounts } = useMsal();
  const dispatch: AppDispatch = useAppDispatch();

  const [files, setFiles] = useState<File[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);

  const refreshFiles = () => {
    setLoading(true);

    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    }).then(({ idToken }) => {
      getFiles(idToken).then((response) => {
        if (response.error) {
          setLoadError(true);

          const msg: Message = { text: response.error, severity: 'error' };
          dispatch(addMessage(msg));
        }

        setFiles(response.files);

        setLoading(false);
        setLoaded(true);
      });
    });
  };

  useEffect(() => {
    refreshFiles();
  }, [instance, accounts]);

  return (
    <FileTableUI
      files={files}
      refreshFiles={refreshFiles}
      loading={loading}
      loaded={loaded}
      loadError={loadError}
    />
  );
}
